/*! `Hootstrap` Bootstrap 4 theme */
@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,400,700);
$font-family-base:Nunito;
$headings-font-family:Nunito;

$primary:#528078;
$secondary:#eed75a;
$success:#FEC100;
$danger:#703B3B;
$info:#63e792;
$warning:#FFE869;
$light:#FDFBF7;
$dark:#555555;

// Add SASS theme customizations here..
