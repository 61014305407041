@import "variables";
@import "bootstrap/bootstrap";

/*
 * Globals
 */

body {
  color: #555;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  font-weight: normal;
  color: #333;
}


/*
 * Override Bootstrap's default container.
 */

@media (min-width: 1200px) {
  .container {
    width: 970px;
  }
}


/*
 * Masthead for nav
 */

.blog-masthead {
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -2px 5px rgba(0,0,0,.1);
          box-shadow: inset 0 -2px 5px rgba(0,0,0,.1);
}

/* Nav links */
.blog-nav-item {
  position: relative;
  display: inline-block;
  padding: 10px;
  font-weight: 500;
  color: #cdddeb;
}
.blog-nav-item:hover,
.blog-nav-item:focus {
  color: #fff;
  text-decoration: none;
}

/* Active state gets a caret at the bottom */
.blog-nav .active {
  color: #fff;
}
.blog-nav .active:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  vertical-align: middle;
  content: " ";
  border-right: 5px solid transparent;
  border-bottom: 5px solid;
  border-left: 5px solid transparent;
}


/*
 * Blog name and description
 */

.blog-header {
  padding-top: 20px;
  padding-bottom: 20px;
}
.blog-title {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 60px;
  font-weight: normal;
}
.blog-description {
  font-size: 20px;
  color: #999;
}


/*
 * Main column and sidebar layout
 */

.blog-main {
  font-size: 18px;
  line-height: 1.5;
}

/* Sidebar modules for boxing content */
.sidebar-module {
  padding: 15px;
  margin: 0 -15px 15px;
}
.sidebar-module-inset {
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.sidebar-module-inset p:last-child,
.sidebar-module-inset ul:last-child,
.sidebar-module-inset ol:last-child {
  margin-bottom: 0;
}


/* Pagination */
.pager {
  margin-bottom: 60px;
  text-align: left;
}
.pager > li > a {
  width: 140px;
  padding: 10px 20px;
  text-align: center;
  border-radius: 30px;
}


/*
 * Blog posts
 */

.blog-post {
  margin-bottom: 60px;
}
.blog-post-title {
  margin-bottom: 5px;
  font-size: 40px;
}
.blog-post-meta {
  margin-bottom: 20px;
  color: #999;
}


/*
 * Footer
 */

.blog-footer {
  padding: 40px 0;
  color: #999;
  text-align: center;
  background-color: #f9f9f9;
  border-top: 1px solid #e5e5e5;
  margin-top: 60px;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}

.masthead {
  margin-top: 55px;
}

.hero-img {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background: url('/assets/img/voyage-to-the-moon.jpg');
  background-size:  cover;
  background-position:  center;
  min-height: 500px;
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.hero-title-img {
  max-width: 100%;
  max-height: 135px;
}

.social-icons {
  margin-bottom: 10px;
}
.social-icons a {
  padding: 0px;
  margin: 6px;
  text-decoration: none;
}
.social-icons svg {
  width: 30px;
  height: 30px;
  fill: $gray-800;
}

.social-icons a:hover > svg {
  fill: black;
}
.dead-link {
  position: relative;
  display: inline-block;
  text-decoration: underline;
  cursor: not-allowed;
  color: gray;
  font-weight: bold;
}

/* Tooltip text */
.dead-link .dead-link-hint {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dead-link:hover .dead-link-hint {
  visibility: visible;
}

#main img.float-left {
  margin-right: 10px;
}
#main img.float-right {
  margin-left: 10px;
}

.post-bottom-nav {
  margin-top: 2rem;
}
